import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import './CurrencyMultipleSearchSelection.css';
import { CurrencyPoolOption } from '../../../types/CurrencyTypes';

interface CurrencyMultipleSearchSelectionProps {
  value: string[];
  setValue: (value: string[]) => void;
  options: CurrencyPoolOption[];
}

const MAX_SELECTION = 6;

const CurrencyMultipleSearchSelection: React.FC<CurrencyMultipleSearchSelectionProps> = (props) => {
  const [selectedCurrencies, setSelectedCurrencies] = useState(props.value);

  const handleChange = (event: React.SyntheticEvent, newValue: CurrencyPoolOption[]) => {
    if (newValue.length <= MAX_SELECTION) {
      const selectedCurrencies = newValue.map((option) => option.value);
      setSelectedCurrencies(selectedCurrencies);
      props.setValue(selectedCurrencies);
    }
  };

  return (
    <div className='CurrencyMultipleSearchSelection'>
          <Autocomplete
            size='small'
            multiple
            options={props.options}
            getOptionLabel={(option) => option.text}
            value={props.options.filter((option) => selectedCurrencies.includes(option.value))}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField 
              {...params} 
              label="Conversion Currencies (Max 6)"
              placeholder="Select Conversion Currencies" 
              variant="outlined" />
            )}
          />
    </div>
  );
};

export default CurrencyMultipleSearchSelection;