import { lightenDarkenColor } from "./ColorUtil";
import { AxialMap, getRadialExpansionSequence } from "./HexagonGridUtils";
import { getDiagonalStepValue, largeNumberFormatter } from "./NumberFormattingUtil";
import { Directions, CurrencyDisplayLayouts, UnitPolygon, getRegionalOrientations } from "./PolygonUtil";
import { ExchangeRateNode } from "../types/CurrencyTypes";
import { CentralBlockColor } from "../constants/DisplayColors";
import { CURRENCY_RATE_DIVISION_TYPES } from "../constants/DisplayTypes";


export function setInitialCurrencyInfoPolygon(
    unitPolygon: UnitPolygon,
    sourceCurrencyEntity: string,
    currencyText: string,
    textBlockColor: string,
    currencyRateDivisionType: CURRENCY_RATE_DIVISION_TYPES) {

    const rateDivisionText = currencyRateDivisionType == CURRENCY_RATE_DIVISION_TYPES.MAIN_DIV_CONVERSION ?
        `${sourceCurrencyEntity}\n/${currencyText}` : `${currencyText}\n/${sourceCurrencyEntity}`;


    unitPolygon.text = rateDivisionText
    unitPolygon.fillColor = textBlockColor;
    unitPolygon.strokeColor = textBlockColor;
    unitPolygon.innerFillColor = textBlockColor;
}

export function setFilledInfoPolygon(
    unitPolygon: UnitPolygon,
    sum: number,
    innerFillColor: string,
    strokeColor: string) {
    unitPolygon.text = largeNumberFormatter(sum);
    unitPolygon.fillColor = innerFillColor;
    unitPolygon.strokeColor = strokeColor;
    unitPolygon.innerFillColor = innerFillColor;
}
export function generatePolygonRegionColorSet(currencyColor: string) {
    let strokeColor = lightenDarkenColor(currencyColor, -20);
    let innerFillColor = lightenDarkenColor(currencyColor, 10);
    let textBlockColor = lightenDarkenColor(currencyColor, 30);
    let emptyBlockColor = lightenDarkenColor(currencyColor, 40);
    return { textBlockColor, strokeColor, emptyBlockColor, innerFillColor };
}
export function setFinalCurrencyInfoPolygon(unitPolygon: UnitPolygon, sum: number, displayValue: number, emptyBlockColor: string, strokeColor: string, innerFillColor: string, stepValue: number) {
    unitPolygon.text = largeNumberFormatter(sum + displayValue);
    unitPolygon.fillColor = emptyBlockColor;
    unitPolygon.strokeColor = strokeColor;
    unitPolygon.innerFillColor = innerFillColor;
    const innerPolygonRatio = displayValue / stepValue;
    unitPolygon.innerPolygonRatio = innerPolygonRatio;
}
export function setEmptyCurrencyGridInfoPolygon(unitPolygon: UnitPolygon, defaultUnitPolygon: UnitPolygon) {
    unitPolygon.text = "";
    unitPolygon.fillColor = defaultUnitPolygon.fillColor;
    unitPolygon.strokeColor = defaultUnitPolygon.strokeColor;
    unitPolygon.innerFillColor = defaultUnitPolygon.innerFillColor;
    unitPolygon.innerPolygonRatio = defaultUnitPolygon.innerPolygonRatio;
}
export function setEmptyCurrencyCenteredInfoPolygon(unitPolygon: UnitPolygon, defaultUnitPolygon: UnitPolygon, strokeColor: string, emptyBlockColor: string) {
    unitPolygon.text = "";
    unitPolygon.innerFillColor = unitPolygon.fillColor;
    //unitPolygon.fillColor = defaultUnitPolygon.fillColor;
    //unitPolygon.strokeColor = strokeColor;
    //unitPolygon.innerFillColor = emptyBlockColor;
    unitPolygon.innerPolygonRatio = 1;
}
export function prepareCenterPolygon(sourceCurrencyEntity: string, unitPolygon: UnitPolygon) {
    let centralTextBlockColor = CentralBlockColor;
    unitPolygon.fillColor = centralTextBlockColor;
    unitPolygon.strokeColor = centralTextBlockColor;
    unitPolygon.innerFillColor = centralTextBlockColor;
    unitPolygon.text = sourceCurrencyEntity;
}
export function decorateCurrencyDisplayPolygons(
    direction: Directions,
    currency: ExchangeRateNode,
    currencyColor: string,
    clonedAxialMap: AxialMap,
    sourceCurrencyEntity: string,
    defaultUnitPolygon: UnitPolygon,
    type: CurrencyDisplayLayouts,
    currencyRateDivisionType: CURRENCY_RATE_DIVISION_TYPES) {

    const regionalOrientations = getRegionalOrientations();

    let polygonCoordinateSequence = getRadialExpansionSequence(
        regionalOrientations[direction]?.x as number,
        regionalOrientations[direction]?.y as number,
        direction
    );

    let displayValue = currency.value;
    let stepValue = getDiagonalStepValue(displayValue);

    let currencyText = currency.entity;


    let { textBlockColor, strokeColor, emptyBlockColor, innerFillColor } = generatePolygonRegionColorSet(currencyColor);
    let emptyBackgrounfBlockColor = defaultUnitPolygon.fillColor;

    let sum = 0;
    polygonCoordinateSequence.forEach((polygonCoordinate, index) => {
        //clonedAxialMap[polygonCoordinate] = {...defaultUnitPolygon};
        const polygon = clonedAxialMap[polygonCoordinate];

        //clean the polygon
        polygon.text = "";
        //polygon.fillColor = defaultUnitPolygon.fillColor;
        //polygon.strokeColor = defaultUnitPolygon.strokeColor;
        //polygon.innerFillColor = defaultUnitPolygon.innerFillColor;
        //polygon.innerPolygonRatio = defaultUnitPolygon.innerPolygonRatio;
        


        polygon.renderingOrder = index;
        if (index === 0) {
            setInitialCurrencyInfoPolygon(polygon, sourceCurrencyEntity, currencyText, textBlockColor, currencyRateDivisionType);
        } else if (displayValue !== 0) {
            if (displayValue < stepValue) {
                setFinalCurrencyInfoPolygon(polygon, sum, displayValue, emptyBlockColor, strokeColor, innerFillColor, stepValue);
                displayValue = 0;
            } else {
                sum += stepValue;
                displayValue -= stepValue;
                setFilledInfoPolygon(polygon, sum, innerFillColor, strokeColor);
            }
        } else {
            setEmptyCurrencyCenteredInfoPolygon(polygon, defaultUnitPolygon, strokeColor, emptyBackgrounfBlockColor);
        }
    });
}

